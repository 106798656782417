.Pages {
    height: 100vh;
}

.Stepper {
    position: fixed;
    height: 100vh;
    right: 10px;
    top: 0;
    display: flex;
    align-items: center;
}

.Steps {
    display: flex;
    flex-direction: column;
}

.Steps>div {
    padding-bottom: 5px;
}

.Steps button {
    min-width: 15px !important;
    width: 15px !important;
    min-height: 15px !important;
    height: 15px !important;
}