.Card {
    padding: 20px 40px;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 20px 0;
    box-shadow: rgba(149, 157, 165, 0.2) 0 0 24px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    border: 1px solid #ffffffaa;
    background-color: #ffffffaa;
    position: relative;
}

.Card h3 {
    margin-top: 10px;
    line-height: 54px;
}

.ant-typography {
    white-space: pre-line;
}

.Card .ant-space {
    width: 100%;
    height: 54px;
    justify-content: flex-start;
}