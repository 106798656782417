body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    min-height: 100vh;
    position: absolute;
}

* {
    scrollbar-width: thin;
    scrollbar-color: #a0a0a0 #ffffff;
}

*::-webkit-scrollbar {
    height: 13px;
    width: 13px;
}

*::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: #ffffff;
}

*::-webkit-scrollbar-track:hover {
    background-color: #ffffff;
}

*::-webkit-scrollbar-track:active {
    background-color: #ffffff;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #a0a0a0;
    border: 3px solid #ffffff;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a0;
}

*::-webkit-scrollbar-thumb:active {
    background-color: #a0a0a0;
}