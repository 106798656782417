.Cover {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333;
    transition: background-color 0.3s 2s ease-in-out;
    position: relative;
}

.Cover.Ready {
    background-color: #f3f3f3;
}

.Logo {
    width: 200px;
    height: 200px;
    left: calc(50vw - 100px);
    top: calc(50vh - 130px);
    position: absolute;
    transition: opacity 0.3s 2s ease-in-out, transform 0.6s 2s ease-in-out,
        top 0.6s 2s ease-in-out;
}

.Logo.No1 {
    fill: #fafafa;
}

.Logo.No2 {
    opacity: 0;
    fill: none;
    stroke: #555;
    stroke-width: 1.5;
}

.Logo.Ready {
    transform: scale(0.7);
    top: calc(30vh - 100px);
    opacity: 1;
}

.Logo.No1>.Base {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.Logo.No1>.Stars {
    opacity: 0;
    transition: opacity 0.3s 0.2s ease-in-out;
}

.Logo.No1>.Knight {
    opacity: 0;
    transition: opacity 0.3s 0.4s ease-in-out;
}

.Logo.No1>.Furina {
    transform: translateY(100%) scale(0);
    opacity: 0;
    transition: transform 0.3s 1s ease-in-out, opacity 0.3s 0.8s ease-in-out;
}

.Logo.No1.Ready>.Furina {
    transform: translateY(0%) scale(1);
    opacity: 1;
}

.Logo.No1.Ready>.Base {
    opacity: 1;
}

.Logo.No1.Ready>.Stars {
    opacity: 1;
}

.Logo.No1.Ready>.Knight {
    opacity: 1;
}

.Prologue {
    text-align: center;
    margin-top: 26vh;
    max-width: 80vw;
}