.ScreenLock {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: -1;
}

.ScreenLock.Active {
    z-index: 1000;
}
