.Footer {
    padding-bottom: 50px;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    background-color: #f3f3f3;
}

.Footer>div {
    text-align: center;
    max-width: 80vw;
}

.Footer .ContactName {
    font-size: 1.1em;
}

.Footer .ContactTitle {
    opacity: 0.5;
}

.Footer .ContactLink {
    display: flex;
    gap: 5px;
    justify-content: center;
}