.BubbleBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
}

.BubbleBackground .Bubble {
    position: absolute;
    bottom: 0;
    width: 20px;
    height: 20px;
    border-radius: 1em;
}

@keyframes rise {
    0% {
        transform: translateY(0) scale(1);
        opacity: 0;
    }

    30% {
        opacity: .1;
    }

    100% {
        transform: translateY(-100vh) scale(2);
        opacity: .2;
    }
}